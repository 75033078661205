import React, { useState, useEffect } from 'react';
import './App.css';
import web3 from './web3';
import deposit from './deposit';
import 'bootstrap/dist/css/bootstrap.min.css';
import metamaskLogo from './metamask_logo.png';
 
function App() {
  const [manager, setManager] = useState('');
  const [userAddress, setUser] = useState('');
  const [userBalance, setBalance] = useState('');
  const [message, setMessage] = useState('');
  const [ether, setEther] = useState('');
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    console.log("execute first getInfo");
    getInfo();
  }, []);

  //ユーザーのアドレスと残高を取得する
  async function getInfo() {
    console.log("getInfo");
    try{
      //コントラクトのデプロイしたmanagerのアドレス取得
      await deposit.methods.manager().call().then(result => setManager(result));
      //メタマスクを使用しているユーザーのアドレスを取得
      await web3.eth.getAccounts().then((account) => {
          setUser(account[0]);
          //メタマスクで使用しているユーザーの残高を取得
          web3.eth.getBalance(account[0]).then(result => {
            const toEther = web3.utils.fromWei(result, 'ether');
            setBalance(toEther);
          });
      });
      //Connect Status = true
      setIsConnected(true);
      setMessage("Waiting for you to enter");
    }catch(error){
      //Connect Status = false
      setIsConnected(false);
      setMessage("Waiting for you to connect");
    }
  }

  //Metamaskに接続する
  const connectWallet = async() => {
    // Check if MetaMask is installed on user's browser
    if(window.ethereum) {
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const chainId = await window.ethereum.request({ method: 'eth_chainId'});
  
      // Check if user is connected to Mainnet
      if(chainId !== '0x4') {
        alert("Connect to Rinkeby Test Network");
      } else {
        //Connect Status = true
        setIsConnected(true);
        setMessage("Waiting for you to enter");
        //ユーザーのアドレスと残高を取得する
        getInfo();
      }
    } else {
      //Connect Status = false
      setIsConnected(false);
      alert("Install Metamask");
      setMessage("Waiting for you to install Metamask");
    }
  }  

  const changeEther = (event) => {
    setEther(event.target.value);
  }

  //ユーザーが入力したetherを送金する
  const sendDeposit = async (event) => {
    //Submitしても画面更新されないようにする
    event.preventDefault();

    //Metamask接続チェック
    await getInfo();
    console.log("after getInfo")
    if(isConnected){
      setMessage('Waiting for the transaction to be mined...');

      //送金処理
      try{
        await deposit.methods.deposit().send({
          from: userAddress,
          value: web3.utils.toWei(ether, 'ether'),
        });
        console.log("after deposit");
        await getInfo();
        setMessage("Transacion is successful !!");
      }catch(error){
        if (error.code === 4001){
          setMessage('User denied transaction signature..');
        }else{
          setMessage('Sorry, something went wrong..');
        }
      }
    }else{
      alert("Please connect to Rinkeby Test Network");
      setMessage("Waiting for you to install Metamask");
    }
  }
 
  //isManagerチェック
  // const isManager = async () => {
  //   const accounts = await web3.eth.getAccounts();
  //   return accounts[0] === manager;
  // }
 
  return (
    <div className="container-fluid bg-dark text-white">
      <div className="row vh-100 d-flex flex-column">
        <div className="d-flex mt-2 ml-2 mr-2 align-items-center">
          <h2 className="nes-react mt-1">Mitsu Web3.0 App</h2>
          <img className="ml-2" src={metamaskLogo} alt="metamask_logo" width="50px" height="50px"/>
        </div>
        <div className="border-bottom"></div>
        <div className="mt-2 ml-2 mr-2">
          <div className="d-flex flex-row">
            <div className="font-weight-bold">This contract is managed by :</div>
            <div className="ml-1">{manager}</div>
          </div>
          <div className="d-flex flex-row">
            <div className="font-weight-bold">User's address :</div>
            <div className="ml-1">{userAddress}</div>
          </div>
          <div className="d-flex flex-row">
            <div className="font-weight-bold">User's balance :</div>
            <div className="ml-1">{userBalance}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div>
              <button onClick={connectWallet}disabled={isConnected} type="button" className="btn btn-success">Connect</button>
            </div>
            {(() => {
              if (isConnected) {
                return(
                  <div className="ml-2 pt-2"> 
                    <p>Already connected!</p>
                  </div>
                )
              }
            })()}
          </div>
        </div>
        <div className="border-bottom mt-5 ml-2 mr-2"></div>
        <div className="mt-2 ml-2 mr-2">
          <form onSubmit={sendDeposit}>
            <h4>Donate your ether to Mitsuki?</h4>
            <div className="input-group mb-3 row col-4">
              <div className="input-group-prepend">
                <span className="input-group-text">ether</span>
              </div>
              <input
                type="number" 
                name="ether" 
                value={ether} 
                onChange={changeEther}
                disabled={!isConnected}
                className="form-control" 
                aria-label="ether" 
              />
            </div>
            <div>
              <button disabled={!isConnected} type="submit" className="btn btn-primary">Send</button>
            </div>
          </form>
        </div>
        {/* { isManager &&
          <>
            <hr />
            <h4>If you are a manger, this part will be displayed</h4>
          </>
        } */}
        <div className="border-bottom mt-1 ml-2 mr-2"></div>
        <div className="mt-2">
          <h4 className="ml-2 mr-2">Message : {message}</h4>
        </div>
        <div className="mt-2 ml-2 mr-2">
          <a href="https://metamask.io/download/" className="text-warning">Install Metamask</a>
        </div>
        <div className="mt-2 ml-2 mr-2">
          <a href="https://faucets.chain.link/rinkeby" className="text-warning">Get ether</a>
        </div>
        <div className="mt-2 ml-2 mr-2">
          <a href="https://rinkeby.etherscan.io/" className="text-warning">Check transactions</a>
        </div>
        <div className="mt-auto mb-3 text-center">© mitsublo.com</div>
      </div>
    </div>
  );
}

export default App;